import React, { Suspense } from 'react'
import i18n from 'i18next'
import { useLocation, Route, Routes, Navigate } from 'react-router-dom'
import Helmet from 'react-helmet'
import styles from './App.module.scss'
import { appConfig } from 'util/core/config/app-config.instance'
import { authService } from 'util/core/service/auth.service'
import { ScrollTop } from 'ui/core/scroll-top/scroll-top'
import Header from 'feature/header/header'
import Footer from 'feature/footer/footer'
import { Loader } from 'ui/core/loader'
import { useUserProfile } from 'util/core/service/user-provider'
import { ResolutionProvider } from 'feature/resolution/resolution-provider'

// Lazy load components

const FeatureSearchTender = React.lazy(async () => await import('./feature/search/feature-search-tender'))
const FeatureMonitoring = React.lazy(async () => await import('./feature/monitoring/feature-monitoring'))
const FeatureTender = React.lazy(async () => await import('./feature/tender/feature-tender'))
const FeatureSearchPlan = React.lazy(async () => await import('./feature/search/feature-search-plan'))
const FeaturePlan = React.lazy(async () => await import('./feature/plan/feature-plan'))
const FeatureSearchContract = React.lazy(async () => await import('./feature/search/feature-search-contract'))
const FeatureSearchClassification = React.lazy(async () => await import('./feature/market/search/feature-search-classification'))
const FeatureSearchCategory = React.lazy(async () => await import('./feature/market/search/feature-search-category'))
const FeatureSearchProduct = React.lazy(async () => await import('./feature/market/search/feature-search-product'))
const FeatureProductMarket = React.lazy(async () => await import('./feature/market/product/product'))
const FeatureMarketCart = React.lazy(async () => await import('./feature/market/cart/cart'))
const FeatureTariffs = React.lazy(async () => await import('./feature/tariffs/feature-tariffs'))
const FeatureContacts = React.lazy(async () => await import('./feature/contacts/feature-contacts'))
const FeatureRegulations = React.lazy(async () => await import('./feature/regulations/feature-regulations'))
const FeatureLogout = React.lazy(async () => await import('./feature/authorization/logout/logout'))
const Homepage = React.lazy(async () => await import('./feature/search/homepage'))
const FeatureError = React.lazy(async () => await import('./feature/error/feature-error'))
const FeatureAuthorization = React.lazy(async () => await import('./feature/authorization/feature-authorization'))
const FeatureCabinet = React.lazy(async () => await import('./feature/cabinet/feature-cabinet'))
const FeatureInfoModal = React.lazy(async () => await import('./feature/info-modal/info-modal'))

const App: React.FC = () => {
  const location = useLocation()
  const { user } = useUserProfile()

  const alternateLinks = Object.keys(appConfig.get('languages')).map(lang => {
    const basename = lang !== appConfig.get('defaultLanguage') ? `/${lang}` : ''
    return {
      rel: 'alternate',
      hrefLang: lang,
      href: `https://www.dzo.com.ua${basename}${location.pathname}`
    }
  })

  return (
    <ResolutionProvider>
        {/* React Helmet here is for dynamic changing meta tags */}

        <Helmet
          title='Офіційний майданчик Prozorro (Прозоро) - Державні публічні закупівлі в Україні: тендери, аукціони, закупки, торги.'
          htmlAttributes={{ lang: i18n.language }}
          meta={[
            {
              name: 'description',
              content: 'Держзакупівлі.Онлайн - офіційний майданчик в системі Prozorro (Прозоро). 🔍 Швидкий пошук тендерів. ⭐ Всі державні публічні закупівлі України. ⬆️ Реєструйтесь та приймайте участь!'
            },
            ...(location.pathname.includes('/js/sign')
              ? [
                  {
                    name: 'robots',
                    content: 'noindex'
                  }
                ]
              : [])
          ]}
          link={[
            ...alternateLinks,
            {
              rel: 'canonical',
              href: `https://www.dzo.com.ua${location.pathname}`
            }
          ]}
        />

        <Header />
        <div className={styles.content}>
          <Routes>
            <Route path='/tenders' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchTender />
              </Suspense>
            } />
            <Route path='/tenders/external/:tenderId' element={
              <Suspense fallback={<Loader />}>
                <FeatureTender />
              </Suspense>
            } />
            <Route path='/tenders/:tenderId/monitorings/:id/*' element={
              <Suspense fallback={<Loader />}>
                <FeatureMonitoring />
              </Suspense>
            } />
            <Route path='/tenders/:id/monitorings' element={
              <Suspense fallback={<Loader />}>
                <Navigate to='/tenders/:id' replace />
              </Suspense>
            } />

            <Route path='/tenders/:id/*' element={
              <Suspense fallback={<Loader />}>
                <FeatureTender />
              </Suspense>
            } />
            <Route path='/plans' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchPlan />
              </Suspense>
            } />
            <Route path='/plans/:id' element={
              <Suspense fallback={<Loader />}>
                <FeaturePlan />
              </Suspense>
            } />

            <Route path='/contracts' element={
              <Suspense fallback={<Loader />}>
                <FeatureSearchContract />
              </Suspense>
            } />

            {/* Remove on release 3.0.0  */}
            {appConfig.get('ENABLE_MARKET') && <>
              <Route path='/market' element={
                <Suspense fallback={<Loader />}>
                  <FeatureSearchClassification />
                </Suspense>
              } />
              <Route path='/market/:id' element={
                <Suspense fallback={<Loader />}>
                  <FeatureSearchCategory />
                </Suspense>
              } />
              <Route path='/market/category/:id' element={
                <Suspense fallback={<Loader />}>
                  <FeatureSearchProduct hasFilters filterBy='category' />
                </Suspense>
              } />
              <Route path='/market/profile/:profileId' element={
                <Suspense fallback={<Loader />}>
                  <FeatureSearchProduct hasFilters filterBy='profile' />
                </Suspense>
              } />
              <Route path='/market/products' element={
                <Suspense fallback={<Loader />}>
                  <FeatureSearchProduct withTitle={false} />
                </Suspense>
              } />
              <Route path='/market/products/:id' element={
                <Suspense fallback={<Loader />}>
                  <FeatureProductMarket />
                </Suspense>
              } />
              <Route path='/market/prozorro-products/:id' element={
                <Suspense fallback={<Loader />}>
                  <FeatureProductMarket isProzorroProduct={true} />
                </Suspense>
              } />
              <Route path='/market/cart*' element={
                <Suspense fallback={<Loader />}>
                { (!authService.isLoggedIn || user?.type === 'seller')
                  ? <FeatureError error="404" />
                  : <FeatureMarketCart />
                }
                </Suspense>
              } />
            </>}

            <Route path='/info/tariffs' element={
              <Suspense fallback={<Loader />}>
                <FeatureTariffs />
              </Suspense>
            } />
            <Route path='/info/contacts' element={
              <Suspense fallback={<Loader />}>
                <FeatureContacts />
              </Suspense>
            } />
            <Route path='/info/regulations' element={
              <Suspense fallback={<Loader />}>
                <FeatureRegulations />
              </Suspense>}
            />

            <Route path='/cabinet/*' element={
              !authService.isLoggedIn
                ? <Navigate to='/' />
                : <>
                  <Suspense fallback={<Loader />}>
                    <FeatureCabinet />
                  </Suspense>
                </>
            }/>

            <Route path='/login/*' element={
              authService.isLoggedIn
                ? <Navigate to='/' replace />
                : <Suspense fallback={<Loader />}>
                    <FeatureAuthorization />
                  </Suspense>
            }/>

            {/* <Route path='/registration*' >
              <Suspense fallback={<Loader />}>
                <FeatureRegistration />
              </Suspense>
              {authService.isLoggedIn && <Redirect to='/' />}
            </Route> */}

            <Route path='logout' element={
              <Suspense fallback={<Loader />}>
                <FeatureLogout />
              </Suspense>
            } />

            <Route path='/' element={
              <Suspense fallback={<Loader />}>
                <Homepage />
              </Suspense>
            }/>

            <Route path="*" element={
              <Suspense fallback={<Loader />}>
                <FeatureError error="404" />
              </Suspense>
            } />
          </Routes>
          <ScrollTop />
          <FeatureInfoModal />
        </div>
        <Footer />
    </ResolutionProvider>
  )
}

export default App
